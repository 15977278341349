.form-styled {
  margin-top: 1.6em;
  margin-bottom: 3.2em;

  h2 {
    margin-top: 1.6em;
    margin-bottom: 0.8em;
  }

  label {
    @include titleText;
    font-size: 1.1em;
  }

  .form-control {
    border-color: $color-gray-darkest;
  }

  .form-text {
    font-size: 0.9em;
  }

  .form-item-description {
    margin-bottom: 1em;
  }

  .form-required {
    label {
      &:after {
        content: " *";
        display: inline;
        color: $color-magenta;
        font-weight: bold;
      }
    }
  }

  .required-asterisk {
    color: $color-magenta;
    font-weight: bold;
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .captcha {
    margin-top: 3em;
  }
}
