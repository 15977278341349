.fatal-error {
  max-width: 600px;
  margin: 1em auto;

  .fatal-error-main {
    font-size: 30px;
    font-weight: bold;
    color: $color-magenta;
    margin-bottom: 0.1em;
    .fa-exclamation-triangle {
      margin-right: 0.3em;
    }
  }

  .fatal-error-secondary {
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  .fatal-error-debug {
    font-size: 0.8em;
  }
}
