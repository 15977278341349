.event-view-detail {
  .event-view-detail-header {
    margin-bottom: 2em;
  }

  .event-title {
    @include titleText;
  }

  .event-site {
    margin-top: 2em;

    a {
      color: $color-black;
      text-decoration: underline;

      &:hover {
        color: $color-link;
      }
    }
  }

  .info-field {
    margin-left: 1.6em * 1.2;
    margin-bottom: 0.1em;
    .icon {
      display: inline-block;
      width: 1.2em;
      text-align: center;
      color: $color-blue;
      margin: 0.1em * 1.2  0.4em  0.3em  -1.6em;
      font-size: 1.2em;
    }
  }

  .event-venue-map {
    height: 350px;
    margin-bottom: 0.5em;
    border: 8px solid white;
    overflow: hidden;
    background-color: $color-gray-light;
  }

  .event-venue-details {
    font-size: 0.9em;
    background-color: white;
    padding: 8px;
    margin-bottom: 1em;
  }
}
