.google-map-embed {
  height: 100%;
  iframe {
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid $color-black;
  }
}

.google-map-filter {
  background-color: $color-white;
  padding: 0.8em;

  .google-map-filter-container {
    margin-bottom: 0.5em;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0.25em 1em 0.25em 1em;
    border-radius: 1em;
    margin-right: 0.5em;
    margin-left: -0.5em;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: $color-gray-light;
    }

    .icon {
      font-size: 1.15em;
      margin-right: 0.25em;
      vertical-align: middle;
    }
  }
}


$map-height: 400px; // Todo: this is hardcoded in the map component
$map-padding: 15px;

.google-map-filter-container {
  height: $map-height;
}

.container-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  margin-bottom: 2em;
  padding: $map-padding;

  @include responsive($xs) {
    position: relative !important;
  }

  .google-map-filter {
    pointer-events: all;
  }

  &.stuck-top {
    position: fixed;
  }

  &.stuck-bottom {
    position: absolute;
    top: auto;
    bottom: $map-height + $map-padding * 2;
    margin-bottom: -1 * ($map-height + $map-padding * 2);
  }
}

.section-filtered-event-list {
  min-height: $map-height + $map-padding * 2 + 64px;
}
