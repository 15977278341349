body {
  @include regularText;
  background-image: url('../../web/assets/img/clouds.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

h1, h2, h3, h4, h5, h6 {
  @include headingText;
}

h1 {
  @include titleText;
  margin-bottom: 0.5em;
  font-size: 2.4rem;
  margin-top: 0.7em;
  color: $color-white;
  background-color: $color-magenta;
  display: inline-block;
  padding: 0.1em 0.2em;
  margin-left: -0.1em;
}

h2 {
  font-size: 2rem;

  @include responsive($xs) {
    font-size: 1.5rem;
  }
}

h2.section-title {
  display: inline-block;
  background-color: $color-white;
  color: white;
  padding: 0.1em 0.4em;
  font-size: 1.5em;
  margin-bottom: 1em;
}

h2.section-title-fullw {
  display: block;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 0.75em;

  @include responsive($xs) {
    font-size: 1.3rem;
  }
}

h4 {
  font-size: 1.2rem;

  @include responsive($xs) {
    font-size: 1.1rem;
  }
}

a {
  color: $color-link;
}

hr {
  border-color: $color-gray-hairline;
  margin: 2em 0;
}

.static-page {
  h1, h2, h3, h4, h5, h6 {
    @include titleText;
  }
}

.lead {
  font-weight: 400;
}

ul {
  padding-left: 1.5em;
}
