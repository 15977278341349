/* Loader from */
/* https://loading.io/css/ */

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid $color-gray;
  border-color: $color-gray transparent $color-gray transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  text-align: center;
  margin: 1em 0;
}

.loader-overlay {
  margin: 2em 0;
}

button {
  .loader {
    display: inline-block;
    margin: 0 0 0 0.5em;
  }

  .lds-dual-ring {
    width: 1em;
    height: 1em;
    vertical-align: text-top;
    &:after {
      width: 1em;
      height: 1em;
      border-width: 0.25em;
      border-color: $color-blue transparent $color-blue transparent;
    }
  }

  &[disabled] {
    .lds-dual-ring:after {
      border-color: $color-black transparent $color-black transparent;
    }
  }
}
