.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: $overlay-z;

  &:before {
    content: " ";
    display: block;
    background-color: rgba(0,0,0,0.65);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    transition: background-color 0.15s;
  }

  .overlay-content {
    position: relative;
    z-index: $overlay-content-z;
    width: 90%;
    max-width: 1200px;
    margin: 2em auto;
    padding: 2em;
    background-color: $color-white;
    background-image: url('../../web/assets/img/gradient-halftone.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 600px 600px;
    transition: opacity 0.15s;
    opacity: 1;

    @include responsive($xs) {
      padding: 1em 0;
    }

    $close-button-size: 40px;

    .overlay-close {
      border: none;
      background-color: transparent;
      position: absolute;
      top: 2px;
      right: 2px;
      font-size: $close-button-size * 0.75;
      width: $close-button-size;
      height: $close-button-size;
      line-height: $close-button-size;
      color: $color-blue;
      cursor: pointer;

      &:focus {
        outline: 2px dashed $color-gray-darkest;
      }
    }
  }

  &.fade-enter {
    &:before {
      background-color: rgba(0,0,0,0);
      transition: background-color 0s;
    }

    .overlay-content {
      opacity: 0;
      transition: opacity 0s;
    }
  }
}

body.with-overlay {
  overflow: hidden;
}
