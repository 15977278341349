.header {
  height: 172px;
  background-color: $color-white;
  margin-bottom: 0;
  padding-top: 1em;
  padding-bottom: 2em;

  @include responsive($xs) {
    height: 140px;
    padding-bottom: 1em;
  }

  .navbar-brand {
    img {
      width: 100%;
      height: auto;
    }

    @include responsive($xs) {
      max-width: 60%;
    }
  }

  .navbar-toggler {
    width: 2em;
    height: 2em;
    font-size: 2em;
    line-height: 2em;
    border: none;
    .fa-bars {
      color: $color-blue;
    }
  }

  .navbar {
    .nav-link {
      @include menuText;
      color: $color-black;
      font-size: 1.15rem;
      margin-left: 1.5em;
      margin-right: 1.5em;
      padding: 0;
      padding-bottom: 3px;
      box-sizing: content-box;

      &:hover {
        color: $color-black;
        border-bottom: 3px solid $color-black;
        padding-bottom: 0px;
      }
    }
  }

  @include responsive($md) {
    .navbar-collapse {
      height: auto !important;
    }
  }

  @include responsive($xs-sm) {
    .navbar-collapse {
      z-index: 1000;
      margin: 2em -1em 0;
      box-shadow: 0px 4px 4px -3px rgba(0, 0, 0, 0.1);
    }
    .navbar-nav {
      background-color: $color-white;
      .nav-item {
        a.nav-link {
          display: block;
          margin: 0;
          padding: 0.3em 1em 0.5em;

          &:hover {
            border: none;
            background-color: $color-gray-light;
          }
        }

        &:last-child {
          a.nav-link {
            padding-bottom: 1em;
          }
        }
      }
    }
  }
}
