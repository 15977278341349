footer {
  margin-top: 5em;
}

.footer-top {
  padding-bottom: 2em;
}
.footer-bottom {
  padding: 4em 0 2.5em;
  background-color: $color-black;
  color: $color-white;

  ul.sitemap {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      margin-bottom: 0.2em;
      a {
        color: $color-white;
      }
    }
  }
}
