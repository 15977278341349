$base-font-size: 16px;
$base-line-height: 1.5;

@mixin regularText {
  @include robotoRegular;
}

@mixin lightText {
  @include firaSansCondensedExtraLight;
  text-transform: none;
}

@mixin headingText {
  @include oswaldSemiBold;
  text-transform: uppercase;
}

@mixin menuText {
  @include oswaldSemiBold;
  text-transform: uppercase;
}

@mixin titleText {
  @include firaSansCondensedSemiBold;
  text-transform: none;
}
