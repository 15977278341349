

ul.event-list {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
  }

  $date-width: 4em;
  $date-height: 5em;

  a.event-teaser {
    display: block;
    padding: 0.6em  0.8em  0.6em  $date-width + 1.5em;
    color: $color-black;
    width: 100%;
    min-height: $date-height;
    overflow: hidden;

    &:hover {
      //background-color: darken($color-white, 5%);
      text-decoration: none;

      .teaser-date {
        background-color: $color-cyan;
      }

      .teaser-title {
        color: $color-blue;
      }
    }

    &.event-teaser-ongoing,
    &.event-teaser-archive, {
      min-height: $date-height + 1.5em;
    }

    .teaser-type {
      font-size: 0.85em;
    }

    .teaser-location {
      font-size: 0.85em;
      margin-top: 0.25em;
    }

    .teaser-title {
      @include titleText;
      line-height: 1.2;
    }

    .teaser-date {
      position: absolute;
      left: 0.5em;
      width: 4em;
      margin-right: 0.8em;
      background-color: $color-yellow;
      color: $color-black;
      @include titleText;

      .teaser-date-prefix {
        text-transform: uppercase;
        @include regularText;
        font-size: 0.8em;
        text-align: center;
        width: 100%;
        display: block;
        background-color: $color-magenta;
        color: $color-white;
        border-bottom: 2px solid white;
      }

      .teaser-date-month {
        display: block;
        text-transform: uppercase;
        text-align: center;
        padding: 0.2em 0.2em 0;
        margin: -1px -1px 0 -1px;
      }

      .teaser-date-day {
        display: block;
        text-align: center;
        font-size: 2em;
        line-height: 1.3;
        margin-top: -0.2em;
        padding-bottom: 0.1em;
      }
    }

    &.event-teaser-archive {
      .teaser-date-prefix {
        background-color: $color-green;
        color: $color-black;
      }
    }
  }
}

ul.event-list-grid {
  li {
    width: 50%;
    display: inline-block;
    vertical-align: top;

    @include responsive($lg) {
      width: 33%;
    }

    @include responsive($xs) {
      width: 100%;
    }
  }
}

.event-list-segment-title {
  @include headingText;
  font-size: 1.35em;
  margin-top: 2em;
  margin-bottom: 0.5em;
  padding-bottom: 0.2em;
  padding-left: 0.5em;
}

.event-grid {
  margin-bottom: 3em;
}

.event-big-teaser {
  color: $color-black;
  display: block;
  margin-bottom: 1.5em;

  &:hover {
    color: $color-black;
    text-decoration: none;
  }

  .teaser-date {
    display: inline-block;
    @include regularText;
    background-color: $color-yellow;
    padding: 0 0.3em;
    margin-right: 0.5em;
    margin-left: -0.2em;

    .teaser-date-month {
      margin-right: 0.3em;
    }
  }

  .teaser-type {

  }

  .teaser-pre-title {
    @include titleText;
  }

  .teaser-title {
    clear: both;
    @include titleText;
    font-size: 1.25em;
    line-height: 1.3;
    margin: 0.15em 0 0.3em 0;
    color: $color-blue;

  }

  .teaser-location {
    display: inline-block;
  }
}

.nav-archive-year {
  margin: 0.5em 0 2em;

  .nav-link {
    @include titleText;
    color: $color-blue;
    border-radius: 0;

    &:hover {
      background-color: $color-green-light;
    }

    &.active {
      background-color: $color-green;
      color: $color-blue;
    }
  }
}

.event-area-fetching {
  position: relative;
  min-height: 3em;

  .loader {
    position: absolute;
    width: 100%;
  }

  .section-filtered-event-list {
    opacity: 0.5;
  }
}
