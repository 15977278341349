$color-black-real: #000;
$color-white-real: #fff;

$color-black: #1f2124;
$color-white: $color-white-real;

$color-gray-light: #eee;
$color-gray: #ccc;
$color-gray-hairline: #d0d0d0;
$color-gray-darkest: #666;

$color-blue: #3426eb;
$color-green: #75ff95;
$color-yellow: #fff369;
$color-magenta: #ff5c9b;
$color-cyan: #9cf9ff;

$color-green-light: rgba(117, 255, 149, 0.5);
$color-cyan-light: #ccfcff;

$color-link: $color-blue;

@mixin neg {
  -webkit-font-smoothing: antialiased;
}
