.section-featured {
  margin-bottom: 3em;
  padding: 2em 0 2em;
  background-image: url('../../web/assets/img/maps/krakow.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .event-grid {
    margin-bottom: 0;
  }

  .event-grid-item {
    margin-bottom: 1.5em;

    @include responsive($xs) {
      margin-bottom: 0;
    }
  }

  .event-big-teaser {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1em;
    transition: transform 0.3s, background-color 0.3s;

    @include responsive($xs) {
      height: auto;
    }

    .teaser-title {
      color: $color-blue;
    }

    &:hover {
      text-decoration: none;
      background-color: $color-white;
      transform: scale(1.05);
      transform-origin: center center;
    }
  }
}

.section-filtered-event-list {
  position: relative;
}
