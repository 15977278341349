$font-stack-fira-sans-condensed: 'Fira Sans Condensed', 'Arial condensed', sans-serif;
$font-stack-roboto: Roboto, Helvetica, Arial, sans-serif;
$font-stack-oswald: Oswald, 'Arial narrow', sans-serif;

@mixin firaSansCondensedExtraLight {
  font-family: $font-stack-fira-sans-condensed;
  font-weight: 200;
  font-style: normal;
}

@mixin firaSansCondensedRegular {
  font-family: $font-stack-fira-sans-condensed;
  font-weight: 400;
  font-style: normal;
}

@mixin firaSansCondensedSemiBold {
  font-family: $font-stack-fira-sans-condensed;
  font-weight: 600;
  font-style: normal;
}

@mixin robotoRegular {
  font-family: $font-stack-roboto;
  font-weight: 400;
  font-style: normal;
}

@mixin oswaldSemiBold {
  font-family: $font-stack-oswald;
  font-weight: 600;
  font-style: normal;
}
